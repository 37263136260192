.refreshStyle {
  -webkit-animation: spinRefresh 1s ease-in-out;
  -moz-animation: spinRefresh 1s ease-in-out;
  animation: spinRefresh 1s ease-in-out;
  font-size: 1.3rem;
}

.refreshStyleLoading {
  -webkit-animation: spinRefresh 4s linear infinite;
  -moz-animation: spinRefresh 4s linear infinite;
  animation: spinRefresh 4s linear infinite;
  font-size: 1.3rem;
}
.refreshStyleLoading path {
  fill: #29132e;
}

@-moz-keyframes spinRefresh {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spinRefresh {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinRefresh {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
