.redMainLoader {
  /* position: absolute; */
  top: 50%;
  left: 50%;
  /* margin: 37px 0 0 -25px; */
  width: 150px;
  height: 150px;
  margin-bottom: 30px
}
.redMainLoader:after {
  content: "";
  background-image: url("./redSpinner.png");
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
  position: absolute;
  margin: -6px;
  width: inherit;
  height: inherit;
  animation: redMainLoader-spin 1.1s linear infinite,1!important;
  -webkit-animation: redMainLoader-spin 1.1s linear infinite,1!important;
}
@keyframes redMainLoader-spin {
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes redMainLoader-spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
